@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
    font-family: 'TT Firs Neue';
    src: url(./TTFirsNeue.woff2) format('woff2'), url(./TTFirsNeue.woff) format('woff');
}

@font-face {
    font-family: 'TT Firs Neue';
    src: url(./TTFirsNeue.woff2) format('woff2'), url(./TTFirsNeue.woff) format('woff');
    font-weight: bold;
}