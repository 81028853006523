:root {
    /* Overrides the border radius setting in the theme. */
    --ck-border-radius: 0 !important;
}

.mention {
    color: #29B9DA;
}

.ck.ck-editor__editable_inline {
    border-color: #aaa !important;
    word-break: break-all;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.ck.ck-editor__editable_inline.ck-read-only {
    border-style: dashed;
    color: #777;
}

.ck.ck-block-toolbar-button {
    background: #29B9DA !important;
    color: white !important;
    min-width: 32px;
    min-height: 32px;
}

.ck-rounded-corners .ck.ck-button.ck-block-toolbar-button {
    border-radius: 20px 0 0 20px;
    padding-left: 6px;
}

.ck.ck-block-toolbar-button.ck-button.ck-on:not(.ck-disabled),
.ck.ck-block-toolbar-button.ck-button:not(.ck-disabled):hover {
    background: #383E4E !important;
    color: white !important;
}

.letter-indicator {
    text-align: right;
}

.letter-indicator.invalid {
    font-weight: bold;
}

/* See https://github.com/ckeditor/ckeditor5/issues/952 */
.ck.ck-dropdown__panel {
    max-height: 30vh;
    overflow-y: auto;
}