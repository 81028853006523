@import './base/fonts/fonts.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --color-primary: #29b9da;
    --color-secondary: #383e4e;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.selection-container {
    user-select: none;
}

.selection-area {
    background: rgba(46, 115, 252, 0.11);
    border: 2px solid var(--color-primary);
    border-radius: 0.1em;
}

/* After mui update,box shadow on main content has been overridden. I could not think of any other better way of doing 
like below or overriding in theme */
#main-content .RaShow-main {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
}


.RaBulkActionsToolbar-topToolbar {
    position: static;
}

.Toastify__toast {
    border-radius: 30px;
    background: #B8E9F4;
    color: #000000;
    padding: 1rem;
    font-family: 'Inter';
    text-align: center;
}

.Toastify__toast-container {
    width: fit-content;
}